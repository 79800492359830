
.w-block1{
    @apply max-w-[500px]
}
.title-level1{
    @apply text-[35px] font-extrabold uppercase w-[80%] leading-[42px]
}
.text-level1{
    @apply text-[29px] font-light uppercase
}

.label-level2{
    @apply text-[19px] font-bold leading-[27px]
}
.text-skin-inverted .label-level2{
    color: #fff;
}
.title-level2{
    @apply  text-[28px] leading-[35px] font-semibold
}
.title-level2-light{
    @apply  text-[28px] leading-[35px] font-light
}
.label-level2{
    color: var(--bg-inverted);
}
.color-inverted{
    color: var(--bg-inverted);
}
.text-level2{
    @apply font-light mt-[5px] !text-[19px] leading-[31px]
}
.text-level3{
    @apply font-light mt-[5px] text-[17px] 
}
.btn{
    background-color: #000;
    color: #fff;
}
.title-resource{
    @apply text-[14px] italic
}
/* .px-page{
    @apply px-[120px]
} */
.pl-page{
    @apply pl-[120px]
}
.pr-page{
    @apply pr-[120px]
}
.pt-page{
    @apply pt-[157px]
}
.bg-block-inverted{
    background-color: #000;
    color: #fff;
  
}
.py-block{
    padding-top: 188px;
    padding-bottom: 188px;
}
.pb-block{
    padding-bottom: 188px;
}
.pt-block{
    padding-top: 188px;
}

.py-block-lg{
    padding-top: 220px;
    padding-bottom: 220px;
}
.bg-block-inverted .title-level2, .bg-block-inverted .color-inverted, .bg-block-inverted .label-level2{
    color: inherit
}
.space-between-blocks{
    @apply space-y-[100px]
}
.space-between-same-block{
    @apply space-y-8
}
.space-between-main-blocks{
    @apply space-y-[120px]
}
.space-between-2cols{
    @apply gap-x-[30px]
}
.bg-container{
    background-color: #ededed;
}
.selected{
    position: relative;
}
.selected::after{
    content: '';
    width: 100%;
    width: 100%;
    height: 8px;
    background: #000;
    display: block;
    top: 47px;
    position: absolute;

}
.title-main{
    @apply text-[42px] leading-[48px] font-bold uppercase;
}
.text-main{
    @apply font-light text-[34px] uppercase mt-[6px];
}
.btn-gray{
    @apply text-level2 block  bg-gray-300 px-7 py-2.5  rounded-lg
}
.btn-gray{
    background-color: '#606060';
}
.max-w-page{
    max-width: 1200px;
}

.active-lng,
.active{
  position: relative;
}
.active-lng::after,
.active::after{
  content: '';
  width: 100%;
  position: absolute;
  background-color: var(--bg-inverted);
  height: 5px;
  left: 0;
  top: 28px;
}
.active::after{
  top: 43px;
  bottom: 0;
}
@media (max-width: 670px) {
    .active::after{
        display: none;
    }
   
}
.active-lng.menu::after{
  background-color: #fff !important;
  top: 27px;
}
.page-type .ant-select-selection-item,
.page-type .ant-select-selector,
.page-type .ant-select-selection-placeholder{
    font-size: 20px !important;
    color: #fff !important;
}
.page-type .ant-select-item-option-content {
    font-size: 20px !important;
}
.page-type .ant-select-arrow{
    color: #fff !important;
}
.pricing-panel .ant-collapse-header{
    margin-left: -4px;
}
.pricing-panel .ant-collapse-content-box,
.pricing-panel .ant-collapse,
.pricing-panel .ant-collapse-header{
    padding: 0 !important;
}
.pricing-panel .ant-collapse-expand-icon{
    margin-top: 8px !important;
}
.pricing-panel{
    margin-top: 0 !important;
    border: inherit !important;
}
.ant-collapse > .ant-collapse-item.pricing-panel {
    border-bottom: 1px solid rgb(229, 231, 235) !important;
}
.pb-end-page{
    padding-bottom: 120px;
}
@media (max-width: 1500px) {
    .max-w-page{
        max-width: 85%;
    }
}
@media (max-width: 750px) {
    .max-w-page{
        max-width: 90%;
    }
}
@media (max-width:1150px) {
    .title-level2{
        @apply  !text-[25px] !leading-[33px] 
    }
    .title-level2-light{
        @apply  !text-[23px] !leading-[33px] 
    }
    .text-level2{
        @apply !text-[20px] !leading-[30px]
    }
    .title-main{
        @apply text-[35px] leading-[45px] ;
    }
    .text-main{
        @apply  text-[30px] ;
    }
}
@media (max-width: 650px) {
    .title-main{
        @apply text-[28px] leading-normal ;
    }
    .text-main{
        @apply  text-[25px] ;
    }
}

@media (max-width: 400px) {
    .title-main{
        @apply text-[22px] leading-normal ;
    }
    .text-main{
        @apply  text-[20px] ;
    }
    .title-level2{
        @apply  !text-[22px] !leading-[31px] 
    }
    .title-level2-light{
        @apply  !text-[22px] !leading-[31px] 
    }
    .text-level2{
        @apply !text-[18px] !leading-[28px]
    }
   
}